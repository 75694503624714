import moment from 'moment'
import consts from '../consts'
import constants from '../consts'

export default {
    defaultParams (type) {
        let params = {
            autoPoweroffKiosks: true
        }

        if (type === 'main' || type === undefined) {
            params = Object.assign(params, {
                mainDefaultModule: 'planning',
                bookingCancellationDaysLimit: 2
            })
        }

        if (type === 'mailer' || type === undefined) {
            params = Object.assign(params, {
                mailerWebsiteName: 'Streamlor',
                mailerWebsiteLink: 'https://www.streamlor.io/',
                mailerLogoUrl: 'https://www.streamlor.io/wp-content/uploads/2019/05/logo_streamlor.png',
                mailerFrom: 'noreply@streamlor.io',
                mailerCopyright: 'Copyright 2019 STREAMLOR.IO ©',
                mailerCustomSpaCreate: '',
                mailerCustomSpaModify: '',
                mailerCustomSpaDelete: '',
                mailerCustomSpaReminder: '',
                mailerCustomActivitiesConfirm: '',
                mailerCustomActivitiesCancel: '',
                mailerCustomSaleConfirmation: '',
                mailerCustomSendAllDocuments: ''
            })
        }

        if (type === 'smser' || type === undefined) {
            params = Object.assign(params, {
                // global SMS context
                smserSender: '',
                smserGroupBy: constants.SMS.BY_ACTIVITY,
                // messages
                smserCustomMessageSpaBookings: '',
                smserAwaitingResponseSpaBookings: true,
                // related operator's client's informations
                smserOperator: constants.SMS.OPERATOR_UNKNOWN,
                smserEndpoint: '',
                smserApplicationKey: '',
                smserApplicationSecret: '',
                smserConsumerKey: '',
                smserSelectedService: ''
            })
        }

        if (type === 'planning' || type === undefined) {
            params = Object.assign(params, {
                planningStartHour: 7,
                planningStartMin: 0,
                planningEndHour: 21,
                planningEndMin: 0,
                planningDays: [1, 2, 3, 4, 5, 6, 0],
                planningShowFilling: true,
                bookingOpeningDate: moment().format('YYYY-MM-DD'),
                numberOfWeekVisible: 4,
                slotVisibilityUnlockWeekday: 1,
                slotVisibilityUnlockTime: "08:00",
                cancellingDelay: 24,
                limitTimeForBooking: 5,
                extendingValidityDateForSubscriptions: false,
                hoursBeforeImmediateBooking: 4,
                maxImmediateBookingsByDay: 0,
                bookingsLimit: 5,
                bookingsLimitNormalConstraint: 5,
                bookingsLimitHighConstraint: 2,
                bookingsLimitMode: constants.BOOKING_RESTRICTION_MODES.SLIDING,
            })
        }

        if (type === 'spa' || type === undefined) {
            params = Object.assign(params, {
                spaAbsencesAmountWarning: 0
            })
        }

        if (type === 'turnstiles' || type === undefined) {
            params = Object.assign(params, {
                bookingMarginMinutes: 40,
                subscriptionPassingDelayMinutes: 10,
                timePassMinDebit: 1,
                timePassMinRemaining: 1,
                timePassMinStop: 0,
                uniqueDebitPerZone: 0,
                requireCovidPass: 0,
                covidPassingDelayMinutes: 30,
                enableLateExitPenalty: 0,
                denyEmptyHourlyPassOnExit: false,
                lateExitDelay: 30,
                lateExitBookingTolerance: 120,
                enableSubExpirationBookingCheck: 0,
                bookingValidatesEntireDay: 0,
                exitDelayBeforePayLater: 10,
                exitDelayAfterPayLater: 5,
                exitUpdelayBeforePayLaterWithSpaBooking: 2,
                durationExitAuthAfterTimeslot: 480,
                showAccessControlProductFamilies: false,
            })
        }

        if (type === 'exports' || type === undefined) {
            params = Object.assign(params, {
                autoExportEnabled: false, 
                autoExportEmail: '',
                autoExportSelected: [],
                autoExportFrequency: '',
                autoExportDay: '',
                autoExportTime: '',
                sageEmail: '',
            })
        }

        if (type === 'vouchers' || type === undefined) {
            params = Object.assign(params, {
                generateSheetAfterSaleConfirmed: false,
            })
        }

        if (type === 'stats' || type === undefined) {
            params = Object.assign(params, {
                statsExportsExcelDisplayNoData: false,
                statsSeason: { start: '01/01', end: '31/12' },
                statsPerformanceTresholds: { first: 25, second: 50, third: 75 }
            })
        }

        if (type === 'lockers' || type === undefined) {
            params = Object.assign(params, {
                lockersDelayBetweenReservations: 5,
                lockersBlockOpeningIfLate: false,
                lockersScheduledHistoryFetch: [],
                lockersIntervalBetweenGatewayLoops: 2,
                lockersUniqueMasterCode: true,
                lockersGlobalMasterCode: '000000',
                lockersBuzzer: false,
                lockersBlockOnFails: false,
                lockersAutoOpening: false,
                lockersAutoOpeningHour: '00:00',
                lockersAutoOpeningDays: [],
                lockersAutoClosing: false,
                lockersLed: 3
            })
        }

        if (type === 'quotations' || type === undefined) {
            params = Object.assign(params, {
                quotationsPaymentTypesCreditCard: true,
                quotationsPaymentTypesDeferred: true,
                quotationsPaymentTypesBankTransfer: true,
                quotationsExpirationDelay: 90,
                quotationsSalesExpirationDelay: 30,
                quotationsVouchersExpirationDelay: 30,
                quotationsMinimumAmount: 0,
                quotationsOrderFooterText: ''
            })
        }

        if (type === 'front-office' || type === undefined) {
            params = Object.assign(params, {
                // paypal
                paypalEnabled: false,
                paypalLiveClientId: "",
                paypalSandboxClientId: "",
                paypalLiveSecretId: "",
                paypalSandboxSecretId: "",
                paypalMode: "live",

                // payfip
                payfipEnabled: false,
                payfipClientId: "",
                payfipMode: "W",

                webEstablishment: ""
            })
        }

        return params
    },

    formatParams (data, type) {
        /* Default values */
        const formattedParams = this.defaultParams(type)

        data.forEach((p) => {
            let res = this.formatParam(p)
            if (res.value != null && formattedParams[res.key] !== undefined) {
                formattedParams[res.key] = res.value
            }
        })

        return formattedParams
    },

    formatParam (p) {
        const camelCaseName = p.ID.replace(/([-_][a-z])/ig, ($1) => {
            return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '')
        })

        let res = { key: camelCaseName, value: null }

        switch (p.ID) {
            /* int */
            case consts.RESA_PARAMS.SMSER_GROUP_BY:
            case consts.RESA_PARAMS.SMSER_OPERATOR:
            case consts.RESA_PARAMS.SPA_PLANNINGS_START_HOUR:
            case consts.RESA_PARAMS.SPA_PLANNINGS_START_MIN:
            case consts.RESA_PARAMS.SPA_PLANNINGS_END_HOUR:
            case consts.RESA_PARAMS.SPA_PLANNINGS_END_MIN:
            case consts.RESA_PARAMS.PLANNING_START_HOUR:
            case consts.RESA_PARAMS.PLANNING_START_MIN:
            case consts.RESA_PARAMS.PLANNING_END_HOUR:
            case consts.RESA_PARAMS.PLANNING_END_MIN:
            case consts.RESA_PARAMS.BOOKING_MARGIN_MINUTES:
            case consts.RESA_PARAMS.NUMBER_OF_WEEK_VISIBLE:
            case consts.RESA_PARAMS.SLOT_VISIBILITY_UNLOCK_WEEKDAY:
            case consts.RESA_PARAMS.CANCELLING_DELAY:
            case consts.RESA_PARAMS.LIMIT_TIME_FOR_BOOKING:
            case consts.RESA_PARAMS.HOURS_BEFORE_IMMEDIATE_BOOKING:
            case consts.RESA_PARAMS.MAX_IMMEDIATE_BOOKINGS_BY_DAY:
            case consts.RESA_PARAMS.MAX_ACTIVE_BOOKINGS:
            case consts.RESA_PARAMS.SUBSCRIPTION_PASSING_DELAY_MINUTES:
            case consts.RESA_PARAMS.TIME_PASS_MIN_DEBIT:
            case consts.RESA_PARAMS.TIME_PASS_MIN_REMAINING:
            case consts.RESA_PARAMS.TIME_PASS_MIN_STOP:
            case consts.RESA_PARAMS.UNIQUE_DEBIT_PER_ZONE:
            case consts.RESA_PARAMS.BOOKING_CANCELLATION_DAYS_LIMIT:
            case consts.RESA_PARAMS.ANONYMIZE_DELAY:
            case consts.RESA_PARAMS.REQUIRE_COVID_PASS:
            case consts.RESA_PARAMS.DELAY_BETWEEN_RESERVATIONS:
            case consts.RESA_PARAMS.INTERVAL_BETWEEN_GATEWAY_LOOPS:
            case consts.RESA_PARAMS.USERCODE_LENGTH:
            case consts.RESA_PARAMS.ENABLE_LATE_EXIT_PENALTY:
            case consts.RESA_PARAMS.LATE_EXIT_DELAY:
            case consts.RESA_PARAMS.LATE_EXIT_BOOKING_TOLERANCE:
            case consts.RESA_PARAMS.ENABLE_SUB_EXPIRATION_BOOKING_CHECK:
            case consts.RESA_PARAMS.BOOKING_VALIDATES_ENTIRE_DAY:
            case consts.RESA_PARAMS.LOCKERS_LED:
            case consts.RESA_PARAMS.COVID_PASSING_DELAY_MINUTES:
            case consts.RESA_PARAMS.LOCKERS_DELAY_BETWEEN_RESERVATIONS:
            case consts.RESA_PARAMS.SPA_ABSENCES_AMOUNT_WARNING:
            case consts.RESA_PARAMS.QUOTATIONS_EXPIRATION_DELAY:
            case consts.RESA_PARAMS.QUOTATIONS_SALES_EXPIRATION_DELAY:
            case consts.RESA_PARAMS.QUOTATIONS_VOUCHERS_EXPIRATION_DELAY:
            case consts.RESA_PARAMS.QUOTATIONS_MINIMUM_AMOUNT:
            case consts.RESA_PARAMS.EXIT_DELAY_BEFORE_PAY_LATER:
            case consts.RESA_PARAMS.EXIT_DELAY_AFTER_PAY_LATER:
            case consts.RESA_PARAMS.EXIT_UPDELAY_BEFORE_PAY_LATER_WITH_SPA_BOOKING:
            case consts.RESA_PARAMS.DURATION_EXIT_AUTH_AFTER_TIMESLOT:
            case consts.RESA_PARAMS.AUTO_EXPORT_DAY:
            case consts.RESA_PARAMS.MEDICERT_VALIDITY:
                res.value = p.Value !== '' ? parseInt(p.Value, 10) : 0
                break
            /* boolean */
            case consts.RESA_PARAMS.SMSER_AWAITING_RESPONSE_SPA_BOOKINGS:
            case consts.RESA_PARAMS.SPA_HUMAN_CONFIRMATION:
            case consts.RESA_PARAMS.SPA_SHOW_SHORT_LABEL:
            case consts.RESA_PARAMS.PLANNING_SHOW_FILLING:
            case consts.RESA_PARAMS.PAYPAL_ENABLED:
            case consts.RESA_PARAMS.PAYFIP_ENABLED:
            case consts.RESA_PARAMS.DENY_EMPTY_HOURLY_PASS_ON_EXIT:
            case consts.RESA_PARAMS.STATS_EXPORTS_EXCEL_DISPLAY_NO_DATA:
            case consts.RESA_PARAMS.LOCKERS_UNIQUE_MASTER_CODE:
            case consts.RESA_PARAMS.LOCKERS_BUZZER:
            case consts.RESA_PARAMS.LOCKERS_BLOCK_ON_FAILS:
            case consts.RESA_PARAMS.LOCKERS_AUTO_OPENING:
            case consts.RESA_PARAMS.LOCKERS_AUTO_CLOSING:
            case consts.RESA_PARAMS.LOCKERS_BLOCK_BUZZER:
            case consts.RESA_PARAMS.LOCKERS_BLOCK_BLOCK_ON_FAILS:
            case consts.RESA_PARAMS.LOCKERS_BLOCK_AUTO_OPENING:
            case consts.RESA_PARAMS.LOCKERS_BLOCK_AUTO_CLOSING:
            case consts.RESA_PARAMS.LOCKERS_BLOCK_OPENING_IF_LATE:
            case consts.RESA_PARAMS.QUOTATIONS_PAYMENT_TYPES_CREDIT_CARD:
            case consts.RESA_PARAMS.QUOTATIONS_PAYMENT_TYPES_BANK_TRANSFER:
            case consts.RESA_PARAMS.QUOTATIONS_PAYMENT_TYPES_DEFERRED:
            case consts.RESA_PARAMS.AUTO_POWEROFF_KIOSKS:
            case consts.RESA_PARAMS.GENERATE_SHEET_AFTER_SALE_CONFIRMED:
            case consts.RESA_PARAMS.WEB_STOCK_ENABLED:
            case consts.RESA_PARAMS.AUTO_EXPORT_ENABLED:
            case consts.RESA_PARAMS.SHOW_ACCESS_CONTROL_PRODUCT_FAMILIES:
                res.value = p.Value === 'true'
                break
            /* string */
            case consts.RESA_PARAMS.SMSER_SENDER:
            case consts.RESA_PARAMS.SMSER_CUSTOM_MESSAGE_SPA_BOOKINGS:
            case consts.RESA_PARAMS.SMSER_ENDPOINT:
            case consts.RESA_PARAMS.SMSER_APPLICATION_KEY:
            case consts.RESA_PARAMS.SMSER_APPLICATION_SECRET:
            case consts.RESA_PARAMS.SMSER_CONSUMER_KEY:
            case consts.RESA_PARAMS.SLOT_VISIBILITY_UNLOCK_TIME:
            case consts.RESA_PARAMS.SMSER_SELECTED_SERVICE:
            case consts.RESA_PARAMS.MAILER_WEBSITE_NAME:
            case consts.RESA_PARAMS.MAILER_WEBSITE_LINK:
            case consts.RESA_PARAMS.MAILER_LOGO_URL:
            case consts.RESA_PARAMS.MAILER_FROM:
            case consts.RESA_PARAMS.MAILER_COPYRIGHT:
            case consts.RESA_PARAMS.MAIN_DEFAULT_MODULE:
            case consts.RESA_PARAMS.MAILER_CUSTOM_SPA_CREATE:
            case consts.RESA_PARAMS.MAILER_CUSTOM_SPA_MODIFY:
            case consts.RESA_PARAMS.MAILER_CUSTOM_SPA_DELETE:
            case consts.RESA_PARAMS.MAILER_CUSTOM_SPA_REMINDER:
            case consts.RESA_PARAMS.PAYPAL_LIVE_CLIENT_ID:
            case consts.RESA_PARAMS.PAYPAL_SANDBOX_CLIENT_ID:
            case consts.RESA_PARAMS.PAYPAL_LIVE_SECRET_ID:
            case consts.RESA_PARAMS.PAYPAL_SANDBOX_SECRET_ID:
            case consts.RESA_PARAMS.PAYPAL_MODE:
            case consts.RESA_PARAMS.PAYFIP_CLIENT_ID:
            case consts.RESA_PARAMS.PAYFIP_MODE:
            case consts.RESA_PARAMS.WEB_ESTABLISHMENT:
            case consts.RESA_PARAMS.LOCKERS_AUTO_OPENING_HOUR:
            case consts.RESA_PARAMS.EXTENDING_VALIDITY_DATE_FOR_SUBSCRIPTIONS:
            case consts.RESA_PARAMS.BOOKING_TERMINAL_PLANNING_TYPE:
            case consts.RESA_PARAMS.MAILER_CUSTOM_ACTIVITIES_CONFIRM:
            case consts.RESA_PARAMS.MAILER_CUSTOM_ACTIVITIES_CANCEL:
            case consts.RESA_PARAMS.MAILER_CUSTOM_SALE_CONFIRMATION:
            case consts.RESA_PARAMS.MAILER_CUSTOM_SEND_ALL_DOCUMENTS:
            case consts.RESA_PARAMS.SAGE_EMAIL:
            case consts.RESA_PARAMS.DEFAULT_OUT_TURNSTILE:
            case consts.RESA_PARAMS.WEB_STOCK_ESTABLISHMENT:
            case consts.RESA_PARAMS.AUTO_EXPORT_EMAIL:
            case consts.RESA_PARAMS.AUTO_EXPORT_FREQUENCY:
            case consts.RESA_PARAMS.AUTO_EXPORT_TIME:
            case consts.RESA_PARAMS.QUOTATIONS_ORDER_FOOTER_TEXT:
                res.value = p.Value || null
                break
            /* json */
            case consts.RESA_PARAMS.PLANNING_DAYS:
            case consts.RESA_PARAMS.STATS_SEASON:
            case consts.RESA_PARAMS.STATS_PERFORMANCE_TRESHOLDS:
            case consts.RESA_PARAMS.LOCKERS_AUTO_OPENING_DAYS:
            case consts.RESA_PARAMS.LOCKERS_SCHEDULED_HISTORY_FETCH:
            case consts.RESA_PARAMS.AUTO_EXPORT_SELECTED:
                res.value = JSON.parse(p.Value)
                break
            /* dates */
            case consts.RESA_PARAMS.BOOKING_OPENING_DATE:
                if (p.Value !== '') res.value = moment(p.Value).format('YYYY-MM-DD')
                break
        }

        return res
    },

    formatGlobalParams (params) {
        var formattedParams = {}
        for (let p of params) {
            switch (p.ID) {
                /* string */
                case constants.GLOBAL_PARAMS.PARAM_TICKET_TEMPLATE:
                    break
            }

            formattedParams[p.ID] = p
        }

        return formattedParams
    },

    parseParams (params) {
        let parsedParams = []

        for (let id in params) {
            let value = params[id]
            if (value !== undefined && value !== null) {
                switch (typeof value) {
                    case "number":
                    case "boolean":
                        value = value.toString()
                        break
                    case "object":
                        value = JSON.stringify(value)
                        break
                }
            } else {
                value = ''
            }
            parsedParams.push({
                ID: id.split(/(?=[A-Z])/).join('_').toLowerCase(),
                Value: value
            })
        }

        return parsedParams
    },
}
