<template>
    <div v-if="client">
        <v-form ref="form">
            <v-btn-toggle
                v-if="!hasQuotationAccess && !clientType"
                v-model="client.ClientType"
                mandatory
                color="primary"
            >
                <v-btn
                    v-for="i in clientTypes"
                    :key="i.value"
                    :value="i.value"
                    :readonly="readonly"
                >
                    <v-icon
                        :color="client.ClientType === i.value ? 'primary' : 'black'"
                        left
                    >
                        {{ i.icon }}
                    </v-icon>
                    {{ i.text }}
                </v-btn>
            </v-btn-toggle>

            <v-subheader v-if="client.ClientType === 2"
            >Informations de l'entité morale</v-subheader
            >
            <div layout="column" v-if="client.ClientType === 2">
                <v-text-field
                    v-model="client.OrgInfo.Name"
                    filled
                    dense
                    clearable
                    prepend-icon="mdi-city"
                    label="Raison sociale*"
                    :rules="[rules.required]"
                    :readonly="readonly"
                />
                <v-select
                    v-model="client.OrgInfo.Type"
                    :items="orgTypes"
                    filled
                    dense
                    attach
                    prepend-icon="mdi-account-tie-outline"
                    label="Type de client*"
                    :rules="[rules.required]"
                    :menu-props="{ 'offset-y': true }"
                    :readonly="readonly"
                />

                <div layout="row">
                    <v-text-field
                        v-model="client.OrgInfo.TVA"
                        filled
                        dense
                        clearable
                        prepend-icon="mdi-bank-outline"
                        :label="'TVA INTRA' + (client.OrgInfo.Type === 1 ? '*' : '')"
                        class="mr-1"
                        :rules="client.OrgInfo.Type === 1 ? [rules.required] : []"
                        :readonly="readonly"
                    />
                    <v-text-field
                        v-model="client.OrgInfo.SIRET"
                        filled
                        dense
                        clearable
                        :label="'SIRET' + (client.OrgInfo.Type === 1 ? '*' : '')"
                        class="ml-1"
                        :rules="client.OrgInfo.Type === 1 ? [rules.required] : []"
                        :readonly="readonly"
                    />
                </div>
                <div layout="row">
                    <v-text-field
                        v-model="client.OrgInfo.RCS"
                        filled
                        dense
                        clearable
                        :label="'RCS' + (client.OrgInfo.Type === 1 ? '*' : '')"
                        class="mr-1 ml-8"
                        :rules="client.OrgInfo.Type === 1 ? [rules.required] : []"
                        :readonly="readonly"
                    />
                    <v-text-field
                        v-model="client.OrgInfo.NAF"
                        filled
                        dense
                        clearable
                        :label="'NAF' + (client.OrgInfo.Type === 1 ? '*' : '')"
                        class="ml-1"
                        :rules="client.OrgInfo.Type === 1 ? [rules.required] : []"
                        :readonly="readonly"
                    />
                </div>
            </div>

            <v-subheader v-if="client.ClientType === 1"
            >Informations générales</v-subheader
            >
            <v-subheader v-else-if="client.ClientType === 2"
            >Personne de contact</v-subheader
            >
            <div layout="row">
                <div flex="20">
                    <v-select
                        v-model="client.Sex"
                        :items="clientsGenders"
                        prepend-icon="mdi-account"
                        label="Civilité"
                        class="mr-1"
                        filled
                        dense
                        attach
                        :menu-props="{ 'offset-y': true }"
                        :readonly="readonly"
                    >
                        <template v-slot:item="{ on, item }">
                            <v-list-item v-on="on">{{ item.civilite }}</v-list-item>
                        </template>
                    </v-select>
                </div>
                <v-text-field
                    v-model="client.Firstname"
                    class="ml-1 mr-1"
                    filled
                    dense
                    :label="
                        'Prénom' +
                            (client.ClientType === 1 || hasQuotationAccess ? '*' : '')
                    "
                    :rules="
                        client.ClientType === 1 || hasQuotationAccess
                            ? [rules.required]
                            : []
                    "
                    :readonly="readonly"
                />
                <v-text-field
                    v-model="client.Lastname"
                    class="ml-1"
                    filled
                    dense
                    :label="
                        'Nom' + (client.ClientType === 1 || hasQuotationAccess ? '*' : '')
                    "
                    :rules="
                        client.ClientType === 1 || hasQuotationAccess
                            ? [rules.required]
                            : []
                    "
                    :readonly="readonly"
                />
            </div>
            <div layout="row">
                <v-menu
                    ref="menuBirthday"
                    v-model="menuBirthday"
                    :close-on-content-click="false"
                    v-if="client.ClientType === 1"
                    transition="offset-y-transition"
                    offset-y
                    attach
                    max-width="290px"
                    min-width="290px"
                    :readonly="readonly"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="birthdateFormatted"
                            filled
                            dense
                            clearable
                            :label="'Date de naissance' + (isClientFieldRequired('BirthDate') ? '*' : '')"
                            :rules="[isClientFieldRule('BirthDate')]"
                            prepend-icon="mdi-calendar"
                            v-on="on"
                            @blur="birthdateDatepicker = parseDate(birthdateFormatted)"
                            @click:clear="birthdateDatepicker = ''"
                            :readonly="readonly"
                        />
                    </template>
                    <v-date-picker
                        v-model="birthdateDatepicker"
                        no-title
                        @input="menu1 = false"
                        :first-day-of-week="1"
                        :readonly="readonly"
                    ></v-date-picker>
                </v-menu>
            </div>

            <!-- Quotations -->
            <div v-if="client.ClientType === 2 && hasRight('admin:quotations')">
                <v-subheader>Compte pro et devis</v-subheader>
                <v-switch
                    v-model="client.OrgInfo.QuotationAccess"
                    label="Accès aux devis"
                    hint="Permet la création de devis et active l'onglet devis pour ce client"
                    persistent-hint inset
                    class="mb-2"
                />
                <v-select
                    v-model="client.OrgInfo.QuotationSalesRepresentativeID"
                    :items="quotationsSalesRepresentatives"
                    item-value="ID"
                    :item-text="
                        (i) => {
                            return i.Firstname + ' ' + i.Lastname
                        }
                    "
                    :loading="loadingQuotationsSalesRepresentatives"
                    :rules="client.OrgInfo.QuotationAccess ? [rules.required] : []"
                    prepend-icon="mdi-account-tie-hat-outline"
                    :label="
                        'Commercial référent pour ce client' +
                            (client.OrgInfo.QuotationAccess ? ' *' : '')
                    "
                    filled
                    dense
                    attach
                    :menu-props="{ 'offset-y': true, bottom: true }"
                    :readonly="readonly"
                />
                <v-select
                    v-model="client.OrgInfo.QuotationProductsCatalogueID"
                    :items="quotationsProductsCatalogues"
                    item-value="ID"
                    item-text="Label.FR"
                    :loading="loadingQuotationsProductsCatalogues"
                    :rules="client.OrgInfo.QuotationAccess ? [rules.required] : []"
                    prepend-icon="mdi-book-open-page-variant-outline"
                    :label="
                        'Catalogue de produits autorisés pour les devis' +
                            (client.OrgInfo.QuotationAccess ? ' *' : '')
                    "
                    filled
                    dense
                    attach
                    :menu-props="{ 'offset-y': true, bottom: true }"
                    :readonly="readonly"
                >
                    <template #no-data>
                        <v-list-item>
                            Il n'existe aucun catalogue pour l'instant
                            <a
                                v-if="!readonly"
                                class="ml-6"
                                @click="
                                    $router.push({
                                        name: 'quotation-settings',
                                        params: { tab: 'catalogs' }
                                    })
                                "
                            >Créer un catalogue</a
                            >
                        </v-list-item>
                    </template>
                </v-select>
                <v-select
                    v-model="client.OrgInfo.QuotationPricingTypeID"
                    :items="quotationsPricings"
                    item-value="ID"
                    item-text="Label.FR"
                    :loading="loadingQuotationsPricings"
                    :rules="client.OrgInfo.QuotationAccess ? [rules.required] : []"
                    prepend-icon="mdi-cash"
                    :label="
                        'Tarification à appliquer aux produits du catalogue' +
                            (client.OrgInfo.QuotationAccess ? ' *' : '')
                    "
                    filled
                    dense
                    attach
                    :menu-props="{ 'offset-y': true, bottom: true }"
                    :readonly="readonly"
                >
                    <template #no-data>
                        <v-list-item>
                            Il n'existe aucun tarif pour l'instant
                            <a
                                v-if="!readonly"
                                class="ml-6"
                                @click="
                                    $router.push({
                                        name: 'quotation-settings',
                                        params: { tab: 'pricings' }
                                    })
                                "
                            >Créer un tarif</a
                            >
                        </v-list-item>
                    </template>
                </v-select>
            </div>

            <v-subheader>Adresse</v-subheader>

            <v-menu
                v-model="showAddressPossibilities" attach bottom offset-y nudge-top="30"
                nudge-right="30"
                nudge-width="-30">
                <template #activator="{}">
                    <v-text-field
                        :value="client.Address.Streetnum ? (client.Address.Streetnum + ' ' + client.Address.Address): client.Address.Address"
                        :rules="hasQuotationAccess ? [rules.required] : [isClientFieldRule('Address.Address')]"
                        dense
                        :label="
                            'Rue' + ((isClientFieldRequired('Address.Address')) ? '*' : '')
                        "
                        prepend-icon="mdi-home-city-outline"
                        filled
                        :readonly="readonly"
                        @input="searchAddressDebounced" />
                </template>
                <v-list two-line>
                    <v-list-item v-for="(addr, idx) in possibleAddresses" :key="idx" @click="selectAddressFromAPI(addr, idx)">
                        <v-list-item-content>
                            <v-list-item-title>{{ addr.Address }}</v-list-item-title>
                            <v-list-item-subtitle>{{ addr.City }}, {{ addr._context }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>

            <div layout="row">
                <v-text-field
                    v-model="client.Address.PostalCode"
                    filled
                    dense
                    :label="'Code Postal' + (hasQuotationAccess || isClientFieldRequired('Address.PostalCode') ? '*' : '')"
                    class="mr-1"
                    prepend-icon="mdi-map-marker"
                    :rules="hasQuotationAccess ? [rules.required] : [isClientFieldRule('Address.PostalCode')]"
                    :readonly="readonly" />
                <v-text-field
                    v-model="client.Address.City"
                    class="ml-1"
                    filled
                    dense
                    :label="'Ville' + (hasQuotationAccess || isClientFieldRequired('Address.City') ? '*' : '')"
                    :rules="hasQuotationAccess ? [rules.required] : [isClientFieldRule('Address.City')]"
                    :readonly="readonly" />
                <v-autocomplete
                    v-model="client.Address.Country"
                    :items="countries(true)"
                    class="ml-1"
                    filled
                    dense
                    :label="'Pays' + (hasQuotationAccess || isClientFieldRequired('Address.Country') ? '*' : '')"
                    :rules="hasQuotationAccess ? [rules.required] : [isClientFieldRule('Address.Country')]"
                    :readonly="readonly" />
            </div>

            <v-subheader>Contact</v-subheader>
            <div layout="row">
                <v-text-field
                    v-model="client.Contact.Phone"
                    filled
                    dense
                    :label="'Téléphone fixe' + (isClientFieldRequired('Contact.Phone') ? '*' : '')"
                    class="mr-1"
                    :rules="[isClientFieldRule('Contact.Phone')]"
                    prepend-icon="mdi-phone"
                    :readonly="readonly"
                />
                <v-text-field
                    v-model="client.Contact.Mobile"
                    filled
                    dense
                    :rules="[isClientFieldRule('Contact.Mobile')]"
                    :label="'Téléphone mobile' + (isClientFieldRequired('Contact.Mobile') ? '*' : '')"
                    class="ml-1"
                    :readonly="readonly"
                />
            </div>
            <v-text-field
                v-model="client.Contact.Email"
                filled
                dense
                :label="'E-mail' + (hasQuotationAccess || isClientFieldRequired('Contact.Email') ? '*' : '')"
                prepend-icon="mdi-email"
                :rules="hasQuotationAccess ? [rules.required] : [isClientFieldRule('Contact.Email')]"
                :readonly="readonly"
                @focusout="checkMail"
                @focusin="mailValidation.Status = null"
            >
                <div
                    slot="append"
                    v-if="mailValidationLoading"
                    class="d-flex align-center"
                >
                    <v-progress-circular
                        indeterminate
                        size="25"
                        color="primary"
                    ></v-progress-circular
                    ><span class="ml-2 primary--text">Vérification...</span>
                </div>
                <div
                    slot="append"
                    v-else-if="mailValidation.Status !== null"
                    layout="row"
                    layout-align="center center"
                >
                    <v-icon
                        v-if="mailValidation.Status === C.MAIL_STATUS_OK"
                        color="success"
                    >mdi-check</v-icon
                    >
                    <v-icon
                        v-else-if="mailValidation.Status === C.MAIL_STATUS_UNDETERMINED"
                        color="orange"
                    >mdi-alert</v-icon
                    >
                    <v-icon v-else color="error">mdi-close</v-icon>
                    <span
                        class="ml-2"
                        :class="{
                            'success--text': mailValidation.Status === C.MAIL_STATUS_OK,
                            'orange--text':
                                mailValidation.Status === C.MAIL_STATUS_UNDETERMINED,
                            'error--text':
                                mailValidation.Status !== C.MAIL_STATUS_OK &&
                                mailValidation.Status !== C.MAIL_STATUS_UNDETERMINED
                        }"
                    >{{ mailValidation.Msg }}</span
                    >
                </div>
            </v-text-field>

            <v-textarea
                v-model="client.Comment"
                filled
                dense
                label="Commentaires"
                prepend-icon="mdi-comment-text"
                rows="3"
                :readonly="readonly"
            />

            <v-subheader v-if="!hasQuotationAccess">Autres</v-subheader>
            <div v-if="!hasQuotationAccess" layout="row">
                <div flex="48">
                    <v-select
                        :value="currentClientPricing.id"
                        :items="pricingOptions"
                        label="Tarification"
                        filled
                        dense
                        attach
                        prepend-icon="mdi-currency-eur"
                        :menu-props="{ 'offset-y': true, top: true }"
                        :persistent-hint="!!currentClientPricing.expiration"
                        :hint="
                            currentClientPricing.expiration
                                ? `Valable jusqu'au ${formatDate(
                                    currentClientPricing.expiration,
                                    'DD/MM/YYYY'
                                )}`
                                : null
                        "
                        :readonly="readonly"
                        @input="_updateClientPricing"
                    />
                </div>
                <div flex="48">
                    <client-autocomplete
                        v-model="client.Parent"
                        label="Compte parent"
                        :external-filters="{ Parent: 'empty' }"
                        class="ml-3"
                        :rules="[isValidParent]"
                        filled
                        dense
                        clearable
                        :disabled="isParent !== false"
                        show-details
                        :readonly="readonly"
                    ></client-autocomplete>
                </div>
            </div>
            <div layout="row" v-if="displayLockersFields && !hasQuotationAccess">
                <v-icon class="mb-5" left>mdi-lock-outline</v-icon>
                <v-text-field
                    v-model="client.LockerCode"
                    name="userlockercode"
                    autocomplete="new-password"
                    label="Code de l'usager pour ouvrir et fermer les casiers"
                    hint="Vous pouvez modifier le code de l'usager ici si besoin"
                    :type="lockerCodeReveal ? 'text' : 'password'"
                    :rules="[rules.userCodeFormat, rules.userCodeMin, rules.userCodeMax]"
                    filled
                    dense
                    :readonly="readonly"
                />
                <v-btn
                    class="mt-3 ml-3"
                    icon
                    @click="lockerCodeReveal = !lockerCodeReveal"
                >
                    <v-icon> {{ lockerCodeReveal ? 'mdi-eye' : 'mdi-eye-off' }} </v-icon>
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import ClientsApi from '@/api/clients.js'
import QuotationsApi from '@/api/quotations.js'
import ClientsMixin from 'neptune/mixins/client'
import LocationMixin from 'neptune/mixins/location'
import AddressMixin from 'neptune/mixins/address'
import C from 'neptune/consts'
import ClientAutocomplete from '../ClientAutocomplete.vue'
import _ from 'lodash'

export default {
    mixins: [ClientsMixin, LocationMixin, AddressMixin],

    components: {
        ClientAutocomplete
    },

    props: {
        client: {
            type: Object,
            required: true
        },
        hasQuotationAccess: {
            type: Boolean,
            default: false,
            required: false
        },
        clientType: {
            type: Number,
            default: null
        }
    },

    data () {
        return {
            orgTypes: [
                { text: 'Société', value: 1 },
                { text: 'Association', value: 2 },
                { text: 'École', value: 3 },
                { text: 'Particulier', value: 8 },
                { text: 'Privé', value: 9 },
                { text: 'Établissement public', value: 10 }
            ],
            rules: {
                required: (value) => !!value || 'Veuillez remplir ce champ',
                userCodeFormat: (value) =>
                    (!isNaN(parseInt(value)) && value >= 0) ||
                    !value ||
                    'Le code ne doit comporter que des chiffres (nombre positif)',
                userCodeMin: (value) =>
                    (value && value.length >= C.LOCKERS.USER_CODE.MIN_LENGTH) ||
                    !value ||
                    'Le code doit comporter au minimum ' +
                    C.LOCKERS.USER_CODE.MIN_LENGTH +
                    ' chiffres',
                userCodeMax: (value) =>
                    (value && value.length <= C.LOCKERS.USER_CODE.MAX_LENGTH) ||
                    !value ||
                    'Le code doit comporter au maximum ' +
                    C.LOCKERS.USER_CODE.MAX_LENGTH +
                    ' chiffres'
            },
            lockerCodeReveal: false,

            birthdateFormatted: '',
            birthdateDatepicker: '',
            menuBirthday: false,

            mailValidation: {
                Status: null,
                Msg: ''
            },
            mailValidationLoading: false,

            isParent: null,
            clientTypes: [
                { text: 'Personne physique', value: 1, icon: 'mdi-account' },
                { text: 'Personne morale', value: 2, icon: 'mdi-city' }
            ],

            quotationsSalesRepresentatives: [],
            quotationsProductsCatalogues: [],
            quotationsPricings: [],
            loadingQuotationsSalesRepresentatives: false,
            loadingQuotationsProductsCatalogues: false,
            loadingQuotationsPricings: false,
            C,
            possibleAddresses: [],
            showAddressPossibilities: false
        }
    },

    computed: {
        ...mapGetters(['currentPeriod', 'hasRight', 'pricing', 'globalParams', 'defaultEstablishment']),

        readonly () {
            return !this.hasRight('admin:clients:manage')
        },

        pricingOptions () {
            const out = []
            this.pricing.forEach((p) => {
                if (
                    p &&
                    p.PeriodID &&
                    p.PeriodID.findIndex((id) => id === this.currentPeriod.ID) !== -1
                ) {
                    out.push({ text: p.Label.FR, value: p.ID })
                }
            })
            return out
        },
        displayLockersFields () {
            const modules = this.$store.getters.modules
            return modules.lockers
        },
        currentClientPricing () {
            if (this.client.Pricings && this.client.Pricings.length > 0) {
                const accountPricing = this.client.Pricings.find(
                    (p) => this.isPast(p.From) && (!p.To || this.isFuture(p.To))
                )
                if (accountPricing) {
                    return {
                        id: accountPricing.PricingID,
                        expiration: accountPricing.To
                    }
                }
            }

            return {
                id: this.currentClientPricingID(this.client, this.currentPeriod ? this.currentPeriod.ID : '')
            }
        },

        clientAddress: {
            get () {
                return (
                    (this.client.Address.Streetnum
                        ? this.client.Address.Streetnum + ' '
                        : '') + this.client.Address.Address
                )
            },
            set (val) {
                if (val) {
                    const re = /(\d+) (.*)/
                    const matches = val.trim().match(re)
                    if (matches && matches.length === 3) {
                        this.client.Address.Streetnum = matches[1]
                        this.client.Address.Address = matches[2].trim()
                    } else {
                        this.client.Address.Streetnum = ''
                        this.client.Address.Address = val.trim()
                    }
                } else {
                    this.client.Address.Streetnum = ''
                    this.client.Address.Address = ''
                }
            }
        },

        requiredClientFields () {
            if (this.globalParams[C.GLOBAL_PARAMS.PARAM_REQUIRED_CLIENT_FIELDS] && this.globalParams[C.GLOBAL_PARAMS.PARAM_REQUIRED_CLIENT_FIELDS].Value) {
                return JSON.parse(this.globalParams[C.GLOBAL_PARAMS.PARAM_REQUIRED_CLIENT_FIELDS].Value)
            }
            return []
        }

    },

    watch: {
        birthdateDatepicker (v) {
            if (v) {
                this.birthdateFormatted = this.formatDate(v)
                this.client.BirthDate = moment(v).format()
            } else {
                this.client.BirthDate = null
            }
        },

        'client.BirthDate' (v) {
            if (v) {
                this.birthdateFormatted = this.formatBirthDate(this.client.BirthDate)
                this.birthdateDatepicker = this.parseDate(this.birthdateFormatted)
            } else {
                this.birthdateFormatted = null
                this.birthdateDatepicker = null
            }
        },

        client () {
            this.checkIsParent()
            this.mailValidation = { Status: null, Msg: '' }
            this.checkMail()
        }
    },

    mounted () {
        this.birthdateFormatted = this.formatBirthDate(this.client.BirthDate)
        this.birthdateDatepicker = this.parseDate(this.birthdateFormatted)

        this.checkIsParent()
        if (this.hasQuotationAccess) {
            this.$emit('update:client', Object.assign(this.client, { ClientType: 2 }))
            this.$emit(
                'update:client',
                Object.assign(this.client.OrgInfo, { QuotationAccess: true })
            )
        }
        if (this.clientType) {
            this.client.ClientType = this.clientType
        }
        if (this.hasRight('admin:quotations')) {
            this.loadingQuotationsSalesRepresentatives = true
            this.loadingQuotationsProductsCatalogues = true
            this.loadingQuotationsPricings = true
            QuotationsApi.getQuotationSalesRepresentatives()
                .then((res) => {
                    this.quotationsSalesRepresentatives = res.data || []
                })
                .catch((e) => {
                    this.$snotify.error('Impossible de charger les commerciaux.')
                    console.error(e)
                })
                .finally(() => {
                    this.loadingQuotationsSalesRepresentatives = false
                })
            QuotationsApi.getQuotationProductsCatalogues({ Status: true })
                .then((res) => {
                    this.quotationsProductsCatalogues = res.data || []
                })
                .catch((e) => {
                    this.$snotify.error(
                        'Impossible de charger les catalogues des produits des devis.'
                    )
                    console.error(e)
                })
                .finally(() => {
                    this.loadingQuotationsProductsCatalogues = false
                })
            QuotationsApi.getQuotationPricings({ Status: true })
                .then((res) => {
                    this.quotationsPricings = res.data || []
                })
                .catch((e) => {
                    this.$snotify.error(
                        'Impossible de charger les tarifications pour les devis.'
                    )
                    console.error(e)
                })
                .finally(() => {
                    this.loadingQuotationsPricings = false
                })
        }
    },

    methods: {
        isValid () {
            return this.$refs.form.validate()
        },

        isValidParent (val) {
            return (!val || val !== this.client.ID) || 'Un client ne peut pas être son propre parent'
        },

        resetForm () {
            this.$refs.form.reset()
            this.$refs.form.resetValidation()
            this.client.ClientType = 1
            if (this.clientType) {
                this.client.ClientType = this.clientType
            }
        },

        checkMail () {
            if (this.client.Contact.Email) {
                this.mailValidationLoading = true
                ClientsApi.checkMail(this.client.Contact.Email).then((res) => {
                    if (res.data !== null) {
                        this.mailValidation = res.data
                        this.mailValidationLoading = false
                    }
                })
            }
        },

        isClientFieldRequired (field) {
            return this.requiredClientFields.includes(field)
        },

        isClientFieldRule (field) {
            return this.isClientFieldRequired(field) ? v => (!!v || 'Ce champ est requis') : true
        },

        checkIsParent () {
            ClientsApi.getClients({
                ParentsIDs: [this.client.ID],
                Deleted: false,
                Limit: 1
            }).then((res) => {
                this.isParent = !!(res.data && res.data.Clients && res.data.Clients.length)
            })
        },

        formatParent (p) {
            let out = p.Firstname + ' ' + p.Lastname
            if (this.formatBirthDate(p.BirthDate)) {
                out += ' - ' + this.formatBirthDate(p.BirthDate)
            }
            if (p.Contact.Email !== '') {
                out += ' - ' + p.Contact.Email
            }
            return out
        },

        _updateClientPricing (newPricingID) {
            this.client = this.updateClientPricing(
                this.client,
                this.pricing.find((p) => p.ID === newPricingID),
                this.currentPeriod.ID
            )
        },

        searchAddressDebounced: _.debounce(function (search) {
            this.searchAddress(search)
        }, 500),

        searchAddress (search) {
            if (search && typeof search === 'object') {
                search = search.Address
            }
            if (!search || (search && search.trim().length < 3)) {
                this.possibleAddresses = []
                return
            }

            let finalSearch = search
            if (this.defaultEstablishment && this.defaultEstablishment.Address && this.defaultEstablishment.Address.PostalCode) {
                finalSearch += '+' + this.defaultEstablishment.Address.PostalCode.substring(0, 2)
            }

            this.searchAddressAPI(finalSearch, null).then((res) => {
                this.possibleAddresses = res.slice(0, 5)
                if (this.possibleAddresses.length) {
                    this.showAddressPossibilities = true
                }
            })
        },

        selectAddressFromAPI (address) {
            this.client.Address = address
            this.client.Address.Country = 'FRANCE'
            this.selectAddress(address.Address)
        },

        setAddress (address) {
            if (address) {
                const re = /(\d+) (.*)/
                const matches = address.trim().match(re)
                if (matches && matches.length === 3) {
                    this.client.Address.Streetnum = matches[1]
                    this.client.Address.Address = matches[2].trim()
                } else {
                    this.client.Address.Streetnum = ''
                    this.client.Address.Address = address.trim()
                }

                // Uppercase if option enabled
                if (this.globalParams[C.GLOBAL_PARAMS.PARAM_ADDRESS_FORCE_UPPERCASE] && this.globalParams[C.GLOBAL_PARAMS.PARAM_ADDRESS_FORCE_UPPERCASE].Value) {
                    this.client.Address.Address = this.client.Address.Address.toUpperCase()
                    this.client.Address.City = this.client.Address.City.toUpperCase()
                }
            } else {
                this.client.Address.Streetnum = ''
                this.client.Address.Address = ''
            }
        },

        selectAddress (address) {
            this.setAddress(address)

            this.possibleAddresses = []
            this.showAddressPossibilities = false
        }
    }
}
</script>

<style lang="scss" scoped>
.v-subheader {
  height: 32px;
  align-items: start;
}
</style>
